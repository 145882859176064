<template>
	<div class="pakete">

		<LandingpageHeader activeTab="pakete"></LandingpageHeader>

		<!-- HEADER -->

		<div class="pakete__header">

			<div class="pakete__header__text">
				<h1>Fitness & Gesundheit<br><span>für Mitarbeiter, Vereinsmitglieder, Gruppen und Patienten</span></h1>

				<p>Die <b>Motivation zum Sport</b> fällt in der Gruppe deutlich leichter. Egal ob als Unternehmen, Verein, Schulklasse oder Coach - SportlerPlus hilft mit <b>vergünstigten Gruppenpreisen</b> dabei die Lust am Sport und einen gesunden Lebensstil in der Gruppe aufrecht zu erhalten.</p>
			</div>

			<div class="pakete__header__image">
				<img src="@/assets/landingpage/pakete-header.jpg" srcset="@/assets/landingpage/pakete-header@2x.jpg 2x" alt="Pakete SportlerPlus">
			</div>

		</div>

		<!-- FIRMEN / GRUPPEN -->

		<div class="pakete__compare">
			<div class="pakete__compare__tabs">
				<v-btn @click="displayTab('firmen')" :class="activeDisplay=='firmen' ? 'is-active' : ''" text v-ripple="false">Für Firmen</v-btn>
				<v-btn @click="displayTab('vereine')" :class="activeDisplay=='vereine' ? 'is-active' : ''" text v-ripple="false">Für Vereine, Schule & Coaches</v-btn>
			</div>
			<div :class="activeDisplay=='firmen' ? 'show' : ''" class="pakete__compare__content">
				<div class="pakete__compare__content__text">
					<h3>Motivieren Sie Ihre Mitarbeiter!</h3>
					<p>Mit SportlerPlus bieten Sie Ihren Mitarbeitern die einfache Möglichkeit sich <b>fit und gesund</b> zu halten. Gerade in Zeiten der vermehrten Arbeit aus dem Heimbüro kommt Bewegung zunehmend zu kurz.</p>
					<p>Mit individuellen Workouts und umfangreichen Gesundheitstips unter Anleitung von <b>Fitness- und Ernährungsexperten</b>, bietet SportlerPlus Ihren Mitarbeitern ein auf die jeweiligen Bedürfnisse anpassbares Fitness- und Gesundheitscoaching.</p>
				</div>
				<div class="pakete__compare__content__features">
					<h3>Ihre Vorteile</h3>
					<ul>
						<li>Förderung der Fitness und Gesundheit Ihrer Mitarbeiter</li>
						<li>Kleine Investition ➞ großer Wert für Ihre Mitarbeiter & Kollegen</li>
						<li>Per App - schnell & einfach</li>
					</ul>
				</div>
			</div>
			<div :class="activeDisplay=='vereine' ? 'show' : ''" class="pakete__compare__content">
				<div class="pakete__compare__content__text">
					<h3>Gemeinsam fit bleiben!</h3>
					<p>SportlerPlus bietet Teams, Vereinen, Schulen und Gruppen die einfache Möglichkeit sich <b>neben dem stationären Angebot</b> auch außerhalb der Sportstätte fitzuhalten.</p>
					<p>Trainer, Coaches und Physios haben mit SportlerPlus die Möglichkeit individuelle Trainings mittels Workout-Playlists zu erstellen und an ihre Sportler oder Patienten als „Hausaufgabe“ weiterzugeben. Dadurch ist die <b>Betreuung größerer Personenzahlen</b> auch ohne oder in Kombination mit persönlichen Coachings und Behandlungen möglich.</p>
				</div>
				<div class="pakete__compare__content__features">
					<h3>Ihre Vorteile</h3>
					<ul>
						<li>Individuelle und auf die Trainigsgruppe abgestimmte Trainingseinheiten</li>
						<li>Das Tranining ganz einfach in der Gruppe organisieren</li>
						<li>Ergänzung zum stationären Angebot</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- BOOKING PAKETE -->

		<div class="pakete__booking">
			<div class="pakete__booking__content">
					<div class="pakete__booking__left">
					<div class="pakete__booking__calculator">
						<div class="pakete__booking__calculator__header">
							<div class="pakete__booking__calculator__header__stepper">
								<img @click="changePackage(-1)" src="@/assets/ic_stepper_large-down.svg" alt="Weniger Personen"/>
							</div>
							<div class="pakete__booking__calculator__header__number">
								<span>Beispielhafte Gruppengröße<sup>1</sup></span>
								{{ activePackage.userLimit }}
								<span>Personen</span>
							</div>
							<div class="pakete__booking__calculator__header__stepper">
								<img @click="changePackage(1)" src="@/assets/ic_stepper_large-up.svg" alt="Mehr Personen"/>
							</div>
						</div>
						<div class="pakete__booking__calculator__content">
							<div class="list">
								<div class="list__item">
									<div class="list__item__content">
										<div class="list__item__label light">
											Preis Pro Person
										</div>
									</div>
									<div class="list__item__value">
										{{ pricePerUser }} € / Monat <sup>2</sup>
									</div>
								</div>
								<div class="list__item">
									<div class="list__item__content">
										<div class="list__item__label light">
											Gesamt
										</div>
									</div>
									<div class="list__item__value">
										{{ packagePrice }} € / Jahr <sup>2</sup>
									</div>
								</div>
							</div>
							<v-btn type="button" @click="openShopLink" class="button button--primary button--small">Weiter</v-btn>
						</div>
					</div>
					<div class="pakete__booking__note">
						<p>
							<small><sup>1</sup> Im Shop kann die exakte Anzahl angegeben werden.<br>
							<sup>2</sup> Alle Preise zuzüglich der gesetzlichen Umsatzsteuer.</small>
						</p>
					</div>
				</div>
				<div class="pakete__booking__text">
					<div class="pakete__booking__badge">
						Ab 2,00€ pro Person / Monat
					</div>
					<h2>Für jede Größe das passende Paket!</h2>
					<p>Wir bieten Pakete für unterschiedliche Gruppengrößen ab 2 Personen. Das gewählte Paket bietet einen 12-monatigen Premiumzugang für die jeweilige Anzahl an Teilnehmern.</p>
					<p>Sie erhalten von uns die entsprechende Anzahl an Premium-Codes, die Sie an Ihre Mitarbeiter, Vereins-, Teammitglieder oder Patienten übermitteln, nach dem Kauf per Email zugestellt. Die App-Codes können von Ihnen anschließend in der Gruppe verteilt werden. Wir benötigen dafür keine Nutzerdaten der einzelnen Teilnehmer. </p>
				</div>
			</div>
		</div>

		<div class="pakete__surprise">
			<div class="pakete__surprise__content">
				<div class="pakete__surprise__text">
					<div class="pakete__booking__badge">
						Fitness-Überraschung
					</div>
					<h2>Individuelle Pakete für Mitarbeiter und Kunden.</h2>
					<p>Überraschen Sie Ihre Mitarbeiter, Kunden oder Partner mit einer Fitness-Überraschung. 12 Monate App-Premium und eine Auswahl aus unserem Equipment- und Nutritionsortiment in Kombination mit der individuellen Absenderschaft Ihrer Marke, sorgen für einen Wow-Effekt. Gerne stellen wir Ihr individuelles Paket zusammen. Wir freuen uns auf Ihre Anfrage! </p>
					<a href="mailto:service@sportlerplus.de" class="button button--primary button--small">Jetzt anfragen</a>
				</div>
				<div class="pakete__surprise__image">
					<img src="@/assets/landingpage/fitness-ueberraschung.png" srcset="@/assets/landingpage/fitness-ueberraschung@2x.png 2x" alt="SportlerPlus Überrarschungspaket">
				</div>
			</div>
		</div>

		<LandingpageFooter></LandingpageFooter>

	</div>
</template>

<script>
import LandingpageHeader from "@/components/LandingpageHeader.vue";
import LandingpageFooter from "@/components/LandingpageFooter.vue";

export default {
	name: 'Pakete',
	components: { LandingpageHeader, LandingpageFooter },
	data() {
		return {
			activeDisplay: "firmen",
			activePackageIndex: 0,
			packages: [
				{userLimit: 5, annualFee: 210, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
				{userLimit: 10, annualFee: 350, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
				{userLimit: 20, annualFee: 640, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
				{userLimit: 30, annualFee: 900, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
				{userLimit: 50, annualFee: 1400, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
				{userLimit: 75, annualFee: 1950, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
				{userLimit: 100, annualFee: 2400, link: 'https://shop.sportlerplus.de/alle-produkte/42/sportler-premium-zugaenge-fuer-firmen-und-vereine?c=25'},
			],
		}
	},
	methods: { 
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				/* TODO: Smooth Transition doesn't work */
				behavior: 'smooth'
			});
		},
		displayTab(name) {
			this.activeDisplay = name;
		},
		changePackage(direction) {
			let newIndex = Math.min(
				Math.max(0, this.activePackageIndex + direction),
				this.packages.length - 1);
			console.log("new package: " + newIndex);
			this.activePackageIndex = newIndex;
		},
		openShopLink() {
			var cPackage = this.packages[this.activePackageIndex];
			window.open(cPackage.link);
		}
	},
	computed: {
		activePackage() {
			return this.packages[this.activePackageIndex];
		},
		pricePerUser() {
			var cPackage = this.packages[this.activePackageIndex];
			var ppu = (Math.round(cPackage.annualFee / cPackage.userLimit / 12 * 100) / 100);
			return ppu.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
		},
		packagePrice() {
			var cPackage = this.packages[this.activePackageIndex];
			return cPackage.annualFee.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
		},
	}
}
</script>

<style lang="scss">

	.pakete {
		position: relative;
		background: #F9F9F9;

		nav {
			z-index: 2;
			position: relative;

			@media screen and (min-width: 768px) {
				background: none;
			}
		}

		footer {
			box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);
		}

		&__header {

			&__text {
				position: relative;
				z-index: 2;
				max-width: 1088px;
				margin: 0px auto;
				padding: 96px 16px 25px;

				@media screen and (min-width: 768px) {
					padding: 104px 24px 88px;
				}

				h1 {
					font-size: 30px;
					line-height: 32px;

					span {
						font-size: 20px;
						line-height: 28px;
						margin-top: 12px;
						font-weight: 600;
						display: flex;
					}

					@media screen and (min-width: 768px) {
						font-size: 48px;
						line-height: 48px;
						letter-spacing: -1px;

						span {
							font-size: 26px;
						}
					}
				}

				p {
					font-size: 17px;
					line-height: 32px;
					letter-spacing: -0.23px;
					opacity: 1;
					margin-top: 39px;
				}
			}

			&__image {
				position: absolute;
				z-index: 0;
				top: 110px;
				left: 0;
				width: 100%;
				height: auto;

				@media screen and (min-width: 768px) {
					top: 0;
				}

				img {
					width: 100%;
				}
			}

		}

		&__compare {
			position: relative;
			z-index: 2;
			max-width: 1088px;
			margin: 0px auto;
			padding: 32px 16px 96px;

			@media screen and (min-width: 768px) {
				padding: 0 24px 128px;
			}

			&__tabs {
				margin-bottom: 24px;

				.v-btn {
					min-width: inherit !important;
					padding: 0 !important;
					font-size: 14px;
					font-weight: 700;
					letter-spacing: -0.23px;
					color: rgba($colorBlack, .48);
					text-transform: inherit;
					border-radius: 0;

					&:not(:last-child) {
						margin-right: 16px;
					}

					@media screen and (min-width: 768px) {
						font-size: 17px;
						letter-spacing: -0.3px;
						font-weight: 600;
						height: 32px !important;

						&:not(:last-child) {
							margin-right: 32px;
						}
					}

					&:before {
						background: none !important;
					}

					&:hover {
						color: rgba($colorBlack, .64);

						&:before {
							background: none !important;
						}
					}

					&.is-active {
						color: $colorPrimary;
						box-shadow: 0 2px 0 0 $colorPrimary;
					}
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				border-radius: 16px;
				background: $colorWhite;
				box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
				overflow: hidden;
				transform: translateY(24px);
				opacity: 0;
				height: 0;
				transition: all .5s ease-in-out;

				@media screen and (min-width: 768px) {
					flex-direction: row;
				}

				&.show {
					transition: all .5s ease-in-out;
					height: inherit;
					opacity: 1;
					transform: translateY(0);
				}

				&__text {
					padding: 24px;

					@media screen and (min-width: 768px) {
						width: 55%;
						padding: 40px 24px 40px 48px;
					}

					h3 {
						font-weight: 900;
						margin-bottom: 17px;
					}

					p {
						opacity: 1;
						color: rgba($colorBlack, .64);
						margin-bottom: 8px;

						&:last-child {
							margin-bottom: 0;
						}

						b {
							color: $colorBlack;
							font-weight: 600;
						}
					}
				}

				&__features {
					padding: 24px 24px 32px;
					background: rgba($colorPrimary, .08);

					@media screen and (min-width: 768px) {
						width: 45%;
						padding: 40px 48px;
					}

					h3 {
						color: $colorPrimary;
						font-weight: 900;
						margin-bottom: 24px;

						@media screen and (min-width: 768px) {
							margin-bottom: 32px;
						}
					}

					ul {
						font-size: 14px;
						line-height: 24px;
						color: $colorBlack;
						font-weight: 600;
						padding: 0;

						li {
							position: relative;
							padding-left: 32px;
							list-style-type: none;

							&:not(:last-child) {
								margin-bottom: 16px;
							}

							&:before {
								content: '';
								position: absolute;
								top: -2px;
								left: 0;
								width: 24px;
								height: 28px;
								background: url('../assets/ic_premium_check.svg')
							}
						}
					}
				}
			}
		}

		&__booking {
			border-top: 1px solid rgba($colorBlack, .08);
			padding: 64px 0 32px;

			@media screen and (min-width: 768px) {
				padding: 128px 0 32px;
			}

			&__content {
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				max-width: 1088px;
				margin: 0px auto;
				padding: 32px 16px 96px;

				@media screen and (min-width: 768px) {
					flex-direction: row;
					align-items: center;
					padding: 0 24px 128px;
				}
			}

			&__left {
				flex: 1;
				max-width: 418px;
				margin: 40px 0 0 0;

				@media screen and (min-width: 768px) {
					margin: 0 80px 0 80px;
				}
			}

			&__calculator {
				background: $colorWhite;
				border-radius: 16px;
				box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
				overflow: hidden;

				&__header {
					display: flex;
					align-items: center;
					padding: 32px 24px;
					box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);

					@media screen and (min-width: 768px) {
						padding: 23px 40px;
					}

					&__number {
						display: flex;
						flex-direction: column;
						text-align: center;
						flex: 1;
						font-size: 84px;
						line-height: 60px;
						font-weight: 900;

						span {
							font-size: 17px;
							color: rgba($colorBlack, .48);
							font-weight: 500;
							line-height: 24px;
							margin: 0 0 24px;

							@media screen and (min-width: 768px) {
								margin: 0;
								line-height: inherit;
							}

							&:last-child {
								margin-top: 24px;

								@media screen and (min-width: 768px) {
									margin: 0;
								}
							}
						}
					}

					&__stepper {
						cursor: pointer;
						-webkit-touch-callout: none;
						-webkit-user-select: none;
						touch-action: manipulation;
					}
				}

				&__content {
					padding: 16px 24px 24px;

					@media screen and (min-width: 768px) {
						padding: 16px 40px 40px;

						.button {
							margin-top: 8px;
						}
					}
				}
			}

			&__note {
				padding: 16px 0 0 16px;
				margin-bottom: -64px;
			}

			&__text {
				flex: 1;

				h2 {
					font-size: 32px;
					margin: 18px 0 25px;
				}
			}

			&__badge {
				display: inline-flex;
				border-radius: 2px;
				background: rgba($colorPrimary, .12);
				color: $colorPrimary;
				padding: 5px 9px;
				font-size: 14px;
				font-weight: 700;
				letter-spacing: -0.29px;
			}
		}

		&__surprise {
			background: $colorBlack;

			&__content {
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				z-index: 2;
				max-width: 1088px;
				margin: 0px auto;
				padding: 64px 16px 128px;

				@media screen and (min-width: 768px) {
					flex-direction: row;
					align-items: center;
					padding: 96px 24px;
				}
			}

			&__text {
				margin-top: 40px;

				@media screen and (min-width: 768px) {
					width: 50%;
					padding-right: 32px;
					margin-top: 0;
				}

				h2 {
					font-size: 32px;
					color: $colorWhite;
					margin: 18px 0 25px;
				}

				p {
					color: $colorWhite;
					margin-bottom: 43px;
				}

				.button {
					text-decoration: none;
					padding-left: 24px !important;
					padding-right: 24px !important;

					@media screen and (min-width: 768px) {
						width: initial;
					}
				}
			}

			&__image {

				@media screen and (min-width: 768px) {
					width: 50%;
				}

				img {
					width: 100%;
					max-width: 300px;

					@media screen and (min-width: 768px) {
						max-width: inherit;
					}
				}
			}
		}
	}
</style>